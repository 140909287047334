.menu {
  background-color: #FD9255;
  display: flex;
  align-items: center;

  height: 120px;
}
.menu img {
  height: 40px;
  width: auto;
}
.menu .menuLinks {
  display: flex;
}

.menu .menuLinks a {
  font-size: 18px;
  text-decoration: none;
  margin-left: 65px;
  color: white;
}

.menu .menuIcon a {
  text-decoration: none;
  color: white;
}

@media (max-width: 720px) {
  .menu {
    justify-content: space-around;
    padding: 0px 0px;
  }

  .menu .menuLinks {
    display: none;
  }
}

@media (min-width: 720px) {
  .menu {
    justify-content: space-evenly;
    padding: 0px 30px;
  }

  .menu .menuIcon {
    display: none;
  }
}

.texto {
  margin-top: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
}

.texto h1 {
  font-size: 48px;
  margin: 0px;
  font-weight: 400;
}

.texto h5 {
  margin: 0px;
  font-size: 16px;
  max-width: 400px;
  font-weight: 400;
  line-height: 30px;
}

* {
  box-sizing: border-box;
}

.contenedor {
  display: grid;
  width: 80vw;
  margin: 80px auto 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 100px) and (max-width: 490px) {
  .contenedor {
    grid-template-columns: repeat(2, 1fr);
  }
  .calculateWidth {
    width: calc(80vw / 1);
  }
  .contenedorButtons{
    flex-direction: column;
  }
  .footer{
    flex-direction: column;
  }
  .footer .panelFooter{
    margin: 0 0 0 30px;
  }
  .footer .button {
    margin-left: 0px !important; 
  }
}
@media (min-width: 490px) and (max-width: 720px) {
  .contenedor {
    grid-template-columns: repeat(4, 1fr);
  }
  .calculateWidth {
    width: calc(80vw / 2);
  }
  .footer{
    flex-direction: column;
  }
  .footer .panelFooter{
    margin: 0 0 0 50px;
  }
  .footer .button {
    margin-left: 0px !important; 
  }
}
@media (min-width: 720px) and (max-width: 970px) {
  .contenedor {
    grid-template-columns: repeat(6, 1fr);
  }
  .masterItems:nth-child(10) {
    grid-column-end: -3;
  }
  .calculateWidth {
    width: calc(80vw / 3);
  }

  .footer{
    flex-direction: column;
  }
  .footer .panelFooter{
    margin: 0 0 0 100px;
  }
  .footer .button {
    margin-left: 0px !important; 
  }
}
@media (min-width: 970px) and (max-width: 1220px) {
  .contenedor {
    grid-template-columns: repeat(8, 1fr);
  }
  .masterItems:nth-child(9) { 
      grid-column-end: -5;
  }
  .calculateWidth {
    width: calc(80vw / 4);
  }
}
@media (min-width: 1220px) {
  .contenedor {
    grid-template-columns: repeat(10, 1fr);
  }
  .calculateWidth {
    width: calc(80vw / 5);
  }
}

.contenedorButtons {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: 40px auto 0 auto;
}

.buttonBase{
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.button {
  width: 177px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
}

.button:hover {
  cursor: pointer;
  box-shadow: 2px 2px 3px rgba(128, 128, 128, 0.472);
}

.buttonClear {
  border: #FD9255 solid 1px;
  color: #FD9255;
  
}
.buttonCalculate {
  background-color: #FD9255;
  color: white;
}


/* Summary */
.summary h1{
  font-size: 36px;
  font-weight: 400;
  text-align: center;
}

.summary .panel {
  max-width: 416px;
  width: 400;
  min-width: 200px;
  height: 322px;
  background: #F9F9F9;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.summary .panel .data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 11px 0;
}

.summary .panel .data h3.dataName {
  width: 70%;
  padding: 0 36px;
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
}
.summary .panel .data h3.dataValue {
  width: 30%;
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
}


.footer {
  width: 100%;
  height: auto;
  background-color: #FD9255;
  height: auto;
  font-weight: 400;
  margin-top: 200px;
  padding: 30px 10vw;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start ;
  color: white;
}

.footer .panelFooter h3{
  margin-bottom: 45px;
  font-weight: 400;
}
.footer .panelFooter h5{
  font-weight: 400;
}

.firstFooter {
  width: 260px;
}

.icon {
  border: solid 2px white;
  border-radius: 100%;
  padding: 2px;
  margin-right: 10px;
  width: 21px;
  height: auto;
}

.footer .button {
  border: solid white 3px;
  border-radius: 5px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.footer .button h3{
  margin: 0px;
  margin-top: -5px;
  font-size: 20px;
}

.footer .button .bIcon{
  margin-right: 5px; 
  width: 30px;
  height: auto;
}